import React, { PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';

const Content = ({ children }: PropsWithChildren) => {
  return (
    <Stack
      flex={1}
      alignItems={{
        mobile: 'center',
        signupLayout: 'flex-start',
      }}
      mx={{
        mobile: 2,
        signupLayout: 8,
      }}
    >
      <Stack
        flex={1}
        maxWidth={{
          mobile: 600,
          signupLayout: 'none',
        }}
        width={{
          mobile: '100%',
          signupLayout: '60%',
        }}
        minWidth={{
          mobile: 'unset',
          signupLayout: 600,
        }}
        mb={3}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default Content;

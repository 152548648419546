import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import NeedHelp from './NeedHelp';
import ScrollableContent from './ScrollableContent';
import SideContainer from './SideContainer';
import TwoSidesLayout from './TwoSidesLayout';

export default TwoSidesLayout;

export { Content, Footer, NeedHelp, Header, ScrollableContent, SideContainer };

import { UserSummary } from '@/state/types';
import { isActiveBuyer, isActiveReferrer, isActiveSeller } from '@/utils/user.utils';

export const getRedirectPathAfterSignIn = (
  userSummary: UserSummary,
  isReferralEnabled: boolean,
) => {
  const isActivatedSeller = isActiveSeller(userSummary);
  const isActivatedBuyer = isActiveBuyer(userSummary);
  const isActivatedReferrer = isActiveReferrer(userSummary);

  if (!isActivatedSeller && !isActivatedBuyer && (!isReferralEnabled || !isActivatedReferrer)) {
    // since we don't have a seller onboarding for now, we just redirect to the buyer onboarding
    return '/onboarding/buyer';
  }

  // For both active buyer and seller, we redirect the buyer route by default
  if (isActivatedBuyer) {
    return '/';
  } else if (isActivatedSeller) {
    // Check in here to keep order of buyer/seller/referrer in order
    // If the user is an active seller, we redirect to the seller route
    return '/seller/offers';
  } else if (isReferralEnabled && isActivatedReferrer) {
    // Redirect to the referral page only if the referral feature is enabled
    return '/referrer';
  }
  return '/onboarding/buyer';
};
